<template>
    <div class="main mobile_container_top_margin">
        <div v-if="!poID">
            <h1>Search Corpay POs</h1>
            <div class="col-sm-12">
                <div class="col-sm-4 form-group">
                    <label>Vendor</label>
                    <input v-model="searchFields.vendor_name" type="text" class="form-control" />
                </div>
                <div class="col-sm-4 form-group">
                    <label>PO Number</label>
                    <input v-model="searchFields.number" type="text" class="form-control" />
                </div>
                <div class="col-sm-4 form-group">
                    <label>Status</label>
                    <select v-model="searchFields.status" class="form-control">
                        <option value="ALL">All</option>
                        <option value="OPEN">Open</option>
                        <option value="DRAFT">Draft</option>
                        <option value="PENDING">Pending</option>
                        <option value="CLOSED">Closed</option>
                        <option value="REJECTED">Rejected</option>
                    </select>
                </div>
                <div class="col-sm-4 form-group">
                    <label>Date From</label>
                    <DatePicker v-model="searchFields.date_after" :inputAttributes="{class: 'form-control'}" />
                </div>
                <div class="col-sm-4 form-group">
                    <label>Date To</label>
                    <DatePicker v-model="searchFields.date_before" :inputAttributes="{class: 'form-control'}" />
                </div>
            </div>
            <div class="col-sm-12">
                <div class="col-sm-4 form-group">
                    <label>Result Size</label>
                    <select class="form-control" v-model="searchFields.items">
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <button class="btn btn-primary" @click="search(true)">Search</button>
                </div>
            </div>
            <div v-if="showLoading" class="col-sm-12">
                <LoadingIcon :active="showLoading" />
            </div>
            <div v-else>
                <table class="table" v-if="searchData">
                    <thead>
                    <tr>
                        <th>PO Number</th>
                        <th>Vendor</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th class="number">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="result in searchData" :key="result.id">
                        <td class="btn-link pointer" style="" @click="loadPo(result.id)">{{ result.number }}</td>
                        <td>{{ result.vendor.name }}</td>
                        <td>{{ new Date(result.date).toLocaleDateString() }}</td>
                        <td>{{ result.status }}</td>
                        <td class="number">{{ result.amount.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="searchData.length" class="col-sm-12">
                <nav>
                    <ul class="pagination">
                        <li class="pointer" @click="decrementPage"><a>&laquo;</a></li>
                        <li class="pointer"><a>{{ searchFields.page }}</a></li>
                        <li class='pointer' @click="incrementPage"><a>&raquo;</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-sm-12">
                    <h1>Corpay {{ poData.number }}</h1>
                    <button @click="poData = {}" class="btn btn-default">Back to Search</button>
                </div>
                <div class="col-sm-6">
                    <h3>Purchase Order</h3>
                    <p><strong>Corpay ID:</strong> {{ poData.id }}</p>
                    <p><strong>PO #:</strong> {{ poData.number }}</p>
                    <p><strong>Status: </strong> {{ poData.status }}</p>
                </div>
                <div class="col-sm-6">
                    <h3>{{ poData.vendor.name }}</h3>
                    <p><strong>Terms:</strong> {{ (poData.term) ? poData.term.name : 'No Terms Set' }}</p>
                    <p><strong>Address:</strong><br />
                        {{ poData.vendor.address1 }} <br />
                        {{ poData.vendor.address2 }} <br />
                        {{ poData.vendor.address3 }} <br />
                        {{ poData.vendor.city }}, {{ poData.vendor.state }} {{ poData.vendor.zipcode }}
                    </p>

                </div>
                <div v-if="activeTabIndex == 'po'" class="col-sm-12">
                    <h3>Items</h3>
                    <div class="table table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th class="number">Unit Price</th>
                            <th class="number">Qty</th>
                            <th class="number">Received</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for='line in poData.po_items' :key='line.id'>
                            <td>{{ line.product_name }}</td>
                            <td class="number">{{ line.unit_price.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }}</td>
                            <td class="number">{{ line.qty.toLocaleString('en') }}</td>
                            <td class="number">{{ line.receipt_item_qty.toLocaleString('en') }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <PrettyJson :json="poData"></PrettyJson>
            </div>
        </div>
    </div>
</template>

<script>


import httpHelpers from "@/store/httpHelpers";
import DatePicker from "@/components/utils/DatePicker.vue";
import DateHelpers from "@/store/DateHelpers";
import LoadingIcon from "@/components/utils/LoadingIcon.vue";
import {store} from "@/store/BusinessObject.store";
import PrettyJson from "@/components/utils/PrettyJson.vue";

export default {
    components: {PrettyJson, LoadingIcon, DatePicker},
    data() {
        return {
            state: store.state,
            poData: {},
            poHistory: {},
            activeTabIndex: 'po',
            poResults: [],
            searchFields: {
                vendor_name: '',
                items: 100,
                page: 1,
                number: '',
                status: 'ALL',
                date_after: DateHelpers.getSundayXWeeksAgo(4),
                date_before: new Date().toLocaleDateString()
            },
            searchData: [],
            paginateMin: 1,
            paginateMax: 1,
            showLoading: false
        }
    },
    methods: {
        loadPo: function(id) {
            let scope = this;
            httpHelpers.post(`corpay_po/to_json/${id}`).then(function(res) {
                scope.poData = res.data.po;
                scope.poHistory = res.data.history;
            });
        },
        search: function(resetPage = false) {
            this.showLoading = true;
            let scope = this;
            if (resetPage) {
                this.searchFields.page = 1;
            }
            httpHelpers.post(`corpay_po/search`, scope.searchFields).then(function(res) {
                Object.assign(scope.searchFields, res.data.meta);
                if (res.data) {
                    scope.paginateMax = res.data.meta.pages;
                    scope.searchData = res.data.data;
                }
                scope.showLoading = false;
            })
        },
        incrementPage: function() {
            if (this.searchFields.page < this.paginateMax) {
                this.searchFields.page++;
            }
        },
        decrementPage: function() {
            if (this.searchFields.page > this.paginateMin) {
                this.searchFields.page--;
            }
        }

    },
    created() {
        this.state.hideNav = true;
        if (this.$route.params.id && this.$route.params.id > 0) {
            this.loadPo(this.$route.params.id);
        }
    },
    watch: {
        pageNumber: function() {
            this.search();
        }
    },
    computed: {
        pageNumber: function() {
            return this.searchFields.page
        },
        poID: function() {
            return (this.poData.id) ? this.poData.id : null;
        }
    }
}

</script>

<style scoped>
    .pointer:hover {
        cursor: pointer
    }
</style>