<template>
    <div v-if="isDeveloper" class="json-viewer">
        <!-- Render object keys -->
        <div v-if="isObject(json)" class="json-item">
            <div v-for="(value, key) in json" :key="key">
                <div class="key">{{ key }}:</div>
                <div class="value">
                    <!-- Recursively render nested objects/arrays with depth check -->
                    <PrettyJson :json="value" :depth="depth + 1" v-if="isObject(value) || Array.isArray(value)" />
                    <!-- Otherwise, just show the value -->
                    <span v-else>{{ formattedValue(value) }}</span>
                </div>
            </div>
        </div>

        <!-- Render array items -->
        <div v-else-if="Array.isArray(json)" class="json-item">
            <div v-for="(item, index) in json" :key="index">
                <div class="key">[{{ index }}]:</div>
                <div class="value">
                    <!-- Recursively render nested objects/arrays with depth check -->
                    <PrettyJson :json="item" :depth="depth + 1" v-if="isObject(item) || Array.isArray(item)" />
                    <!-- Otherwise, just show the value -->
                    <span v-else>{{ formattedValue(item) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppStore from "@/store/App.store";

export default {
    name: 'PrettyJson',
    props: {
        json: {
            type: [Object, Array],
            required: true
        },
        depth: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isDeveloper() {
            return AppStore.isDeveloper();
        }
    },
    methods: {
        // Improved method to check if the value is a plain object
        isObject(value) {
            return value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date) && !(value instanceof RegExp);
        },

        // Safe formatting of large numbers or other special data types
        formattedValue(value) {
            if (value === null) {
                return 'null';
            } else if (typeof value === 'boolean') {
                return value ? 'true' : 'false';
            } else if (typeof value === 'number') {
                // Optionally format large numbers for better readability
                return value > 999999 ? value.toExponential(2) : value;
            }
            return value;
        },

        // Detect circular references
        hasCircularReference(obj) {
            const seenObjects = new Set();

            function detectCycle(value) {
                if (value && typeof value === 'object') {
                    if (seenObjects.has(value)) {
                        return true; // Circular reference detected
                    }
                    seenObjects.add(value);
                    for (const key in value) {
                        if (value.hasOwnProperty(key) && detectCycle(value[key])) {
                            return true;
                        }
                    }
                }
                return false;
            }

            return detectCycle(obj);
        },

        // Limit recursion depth to avoid stack overflow on very deep objects
        isSafeDepth() {
            return this.depth < 5; // Set your preferred max depth here
        }
    }
};
</script>

<style scoped>
.json-viewer {
    max-height: 800px; /* Increased height for larger objects */
    overflow-y: auto;
    font-family: monospace;
    background-color: #f4f4f4;
    padding: 10px;
}

.json-item {
    margin-bottom: 8px;
}

.key {
    font-weight: bold;
    margin-right: 5px;
}

.value {
    display: inline-block;
    margin-left: 10px;
}

ul {
    padding-left: 20px;
}

li {
    list-style-type: none;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}
</style>
